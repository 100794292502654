import '@/assets/scss/main.scss';
import Vue from 'vue'
import VueRouter from "vue-router";
import HelloWorld from "@/components/HelloWorld.vue";
import PlacerLogo from "@/components/PlacerLogo.vue";
import HelloI18n from "@/components/HelloI18n.vue";
import IndexPage from "@/pages/index.vue";
import App from './App.vue'
import i18n from './i18n'

Vue.use(VueRouter);

const routes = [
    {
        path: "",
        component: IndexPage,
    },
    {
        path: "/i18",
        component: HelloI18n,
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
});

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

