import { render, staticRenderFns } from "./PlacerHeader.vue?vue&type=template&id=152ee81a&"
import script from "./PlacerHeader.vue?vue&type=script&lang=ts&"
export * from "./PlacerHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports