<template>
  <section class="partners">
    <div class="container">
      <div class="row">
        <!-- Heading Title Start -->
        <heading-title>{{ $t('section.partners.title') }}</heading-title>
        <!-- Heading Title End -->

        <div class="partners__logo">
<!--          <sport-life-svg width='300' height="93" alt="" />-->
          <img v-for='i in 7' :key="i" src="@/assets/img/_src/sport-life-club.svg" width="300" height="93" alt="">
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <img src="img/_src/898798e3f2016767434e061a29e76d40_1 1.svg" width="300" height="93" alt="">-->
<!--          <sport-life-->
        </div>

        <div class="partners__form">
          <div class="title">{{ $t('section.partners.title2')}}</div>
          <form action="#" class="">
            <div class="rows_one">
              <div class="columns__one">
                <input type="text" :placeholder="$t('section.partners.fio')">
                <input type="text" :placeholder="$t('section.partners.phone')">
              </div>

              <textarea :placeholder="$t('section.partners.msg')"></textarea>
            </div>
            <div class="rows_two">
              <p>{{ $t('section.partners.legal1') }}
                <a href="#">{{ $t('section.partners.legal2') }}</a>
              </p>
              <input type="submit" :value="$t('section.partners.submit')" class="find-club__submit">
            </div>

          </form>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// import SportLifeSvg from '../svg/sport-life.svg'
// import HeadingTitle from '@/components/heading-title';
export default {
  name: 'Partners',
  // components: { HeadingTitle },
  // components: {
  //   SportLifeSvg
  // }
};
</script>


