
































































































import Vue from 'vue';
import PlacerLogo from '@/components/PlacerLogo.vue'

export default Vue.extend({
    name: "PlacerHeader",
    components: {
        PlacerLogo,
    },
    data() {
        return {
            selectedCity: {},
            cityname: '',
            cities: [],
            styles: {
                cursor: 'pointer',
                opacity: '.7'
            }
        }
    },
    methods: {
    },
    computed: {
    },
});
