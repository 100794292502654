




import Vue from 'vue'

export default Vue.extend({
    name: 'HelloI18n'
})
