<template>
  <section class="recommend_visit ">
    <div class="container">
      <row>
        <heading-title>{{ $t('section.recommend.title') }}</heading-title>
      </row>

      <row additional='position-relative'>
        <div class="recommend_visit__content swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">

          <div id="swiper-wrapper-c1ab4321035a10309" class="recommend_visit__content__wrapper swiper-wrapper" aria-live="polite" style="transform: translate3d(0px, 0px, 0px);">
            <!-- Recommend to Visit Item Start -->
            <SwiperSlideItem v-for="(item, index) in items" :key="index" :item="item" :aria-label="getAriaLabel(index)" />

<!--            <swiper-slide-item :item="items[1]" aria-label="2 / 9" />-->
<!--            <div class="recommend_visit__item swiper-slide swiper-slide-active" role="group" aria-label="1 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
            <!-- Recommend to Visit Item End -->

            <!-- Recommend to Visit Item Start -->
<!--            <div class="recommend_visit__item swiper-slide swiper-slide-next" role="group" aria-label="2 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
            <!-- Recommend to Visit Item End -->

            <!-- Recommend to Visit Item Start -->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="3 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
            <!-- Recommend to Visit Item End -->

            <!-- Recommend to Visit Item Start -->
<!--            <swiper-slide-item :item="items" />-->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="4 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
            <!-- Recommend to Visit Item End -->

            <!-- Recommend to Visit Item Start -->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="5 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
<!--            &lt;!&ndash; Recommend to Visit Item End &ndash;&gt;-->

<!--            &lt;!&ndash; Recommend to Visit Item Start &ndash;&gt;-->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="6 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
<!--            &lt;!&ndash; Recommend to Visit Item End &ndash;&gt;-->

<!--            &lt;!&ndash; Recommend to Visit Item Start &ndash;&gt;-->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="7 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
<!--            &lt;!&ndash; Recommend to Visit Item End &ndash;&gt;-->

<!--            &lt;!&ndash; Recommend to Visit Item Start &ndash;&gt;-->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="8 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
<!--            &lt;!&ndash; Recommend to Visit Item End &ndash;&gt;-->

<!--            &lt;!&ndash; Recommend to Visit Item Start &ndash;&gt;-->
<!--            <div class="recommend_visit__item swiper-slide" role="group" aria-label="9 / 9" style="width: 393.333px; margin-right: 30px;">-->

<!--              &lt;!&ndash; Recommend to Visit Item Image Start &ndash;&gt;-->
<!--              <a href="#" class="recommend_visit__item__image">-->
<!--                <img src="img/_src/images.jpg" alt="">-->
<!--              </a>-->
<!--              &lt;!&ndash; Recommend to Visit Item Image End &ndash;&gt;-->

<!--              &lt;!&ndash; Recommend to Visit Item Title Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__title">-->
<!--                <a href="#">-->
<!--                  Kiev Sport Club-->
<!--                </a>-->
<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Title End &ndash;&gt;-->

<!--              <div class="recommend_visit__item__meta">-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Hearth &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/hearth.svg" alt="">-->
<!--                  <span>-->
<!--										439-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item Comments &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/comments.svg" alt="">-->
<!--                  <span>-->
<!--										3-->
<!--									</span>-->
<!--                </div>-->

<!--                &lt;!&ndash; Recommend to Visit Item Meta Item View &ndash;&gt;-->
<!--                <div class="recommend_visit__item__meta_item">-->
<!--                  <img src="img/_src/view.svg" alt="">-->
<!--                  <span>-->
<!--										345-->
<!--									</span>-->
<!--                </div>-->

<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/map.svg" alt="">-->
<!--                <span>-->
<!--								бульвар Дружбы Народов, 5-->
<!--							</span>-->
<!--              </div>-->

<!--              &lt;!&ndash; Recommend to Visit Item Line &ndash;&gt;-->
<!--              <div class="recommend_visit__item__line">-->
<!--                <img src="img/_src/phone.svg" alt="">-->
<!--                <a href="tel:+380445228866">-->
<!--                  (044) 522-88-66-->
<!--                </a>-->
<!--              </div>-->


<!--              &lt;!&ndash; Recommend to Visit Item Footer Start &ndash;&gt;-->
<!--              <div class="recommend_visit__item__footer">-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="book-button find-club__submit">-->
<!--                  Забронировать-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--                &lt;!&ndash; Book Button Start &ndash;&gt;-->
<!--                <a href="single-book.html" class="more-link edit">-->
<!--                  Подробнее-->
<!--                </a>-->
<!--                &lt;!&ndash; Book Button End &ndash;&gt;-->

<!--              </div>-->
<!--              &lt;!&ndash; Recommend to Visit Item Footer End &ndash;&gt;-->

<!--            </div>-->
            <!-- Recommend to Visit Item End -->
          </div>

          <!-- If we need navigation buttons -->
          <div class="custom__swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-c1ab4321035a10309" aria-disabled="true"></div>
          <div class="custom__swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-c1ab4321035a10309" aria-disabled="false"></div>

          <!-- If we need scrollbar -->
          <div class="swiper-scrollbar"><div class="swiper-scrollbar-drag" style="transform: translate3d(0px, 0px, 0px); width: 398.899px;"></div></div>

          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
      </row>

    </div>

  </section>
</template>

<script>
// import { Component, Prop, Vue } from "nuxt-property-decorator";
// import Row from '@/components/row.vue';
// import HeadingTitle from '@/components/heading-title.vue';
// import SwiperSlideItem from "@/components/section/recommend-visit/swiper-slide-item.vue";
// import { SportCardInterface } from "@/types";

// @Component({
//   components: { SwiperSlideItem, HeadingTitle, Row },
// })
export default {

  name: "RecommendVisit",

  data() {
    return {
      // items: SportCardInterface[],
      items: [],
    }
  },

  // @Prop({ type: Array, required: true })
  // items: SportCardInterface[];

  methods: {
    getAriaLabel(index) {
      return `${index + 1}/ 9`;
    }
  },
}
</script>


