module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "privatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en������ �������"])},
        "clubPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en�������� �����-�����"])},
        "treners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en�������"])},
        "toOrganizators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en�������������"])},
        "toPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en�����������"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en��. ������� ������������� 12, ���� 1"])},
        "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enHello i18n in SFC! -111"])}
      },
      "ru": {
        "privatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru������ �������"])},
        "clubPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru�������� �����-�����"])},
        "treners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru�������"])},
        "toOrganizators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru�������������"])},
        "toPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru�����������"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru��. ������� ������������� 12, ���� 1"])},
        "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru������"])}
      },
      "uk": {
        "privatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk������ �������"])},
        "clubPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk�������� �����-�����"])},
        "treners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk�������"])},
        "toOrganizators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk�������������"])},
        "toPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk�����������"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk��. ������� ������������� 12, ���� 1"])},
        "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uk������!!!"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"privatePage":"en������ �������","clubPage":"en�������� �����-�����","treners":"en�������","toOrganizators":"en�������������","toPartners":"en�����������","address":"en��. ������� ������������� 12, ���� 1","test":"enHello i18n in SFC! -111"},"ru":{"privatePage":"ru������ �������","clubPage":"ru�������� �����-�����","treners":"ru�������","toOrganizators":"ru�������������","toPartners":"ru�����������","address":"ru��. ������� ������������� 12, ���� 1","test":"ru������"},"uk":{"privatePage":"uk������ �������","clubPage":"uk�������� �����-�����","treners":"uk�������","toOrganizators":"uk�������������","toPartners":"uk�����������","address":"uk��. ������� ������������� 12, ���� 1","test":"uk������!!!"}}')
  delete Component.options._Ctor
  
}
