<template>
    <footer>
        <div class="container">
            <div class="row">
                <ul>
                    <li>
                        <router-link to="/my/account">
                            {{ $t('privatePage') }}
                        </router-link>
                    </li>

                    <li>
                        <router-link to="/sport/club/single">
                            {{ $t('clubPage') }}
                        </router-link>
                    </li>

                    <li>
                        <router-link to="/">
                            {{ $t('treners') }}
                        </router-link>
                    </li>

                    <li>
                        <router-link to="#">
                            {{ $t('toOrganizators') }}
                        </router-link>
                    </li>

                    <li>
                        <router-link to="#">
                            {{ $t('toPartners') }}
                        </router-link>
                    </li>

                </ul>
            </div>
            <div class="row">
                <div class="footer__middle-bar">
                    <a href="tel:(044) 234 45 67">(044) 234 45 67</a>
                    <span>{{ $t('address') }}</span>
                    <ul>
                        <li>
                            <a href="#" target="_blank">
                                <img src="../assets/img/_src/instagram 1.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="../assets/img/_src/facebook 1.svg" alt="">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="../assets/img/_src/youtube 1.svg" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'PlacerFooter',
        data() {
            this.$i18n.locale = 'ru';
            return { locale: 'ru' }
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            }
        }
    });
</script>

<i18n>
    {
    "en": {
    "privatePage": "en������ �������",
    "clubPage": "en�������� �����-�����",
    "treners": "en�������",
    "toOrganizators": "en�������������",
    "toPartners": "en�����������",
    "address": "en��. ������� ������������� 12, ���� 1",
    "test": "enHello i18n in SFC! -111"
    },
    "ru": {
    "privatePage": "ru������ �������",
    "clubPage": "ru�������� �����-�����",
    "treners": "ru�������",
    "toOrganizators": "ru�������������",
    "toPartners": "ru�����������",
    "address": "ru��. ������� ������������� 12, ���� 1",
    "test": "ru������"
    },
    "uk": {
    "privatePage": "uk������ �������",
    "clubPage": "uk�������� �����-�����",
    "treners": "uk�������",
    "toOrganizators": "uk�������������",
    "toPartners": "uk�����������",
    "address": "uk��. ������� ������������� 12, ���� 1",
    "test": "uk������!!!"
    }
    }
</i18n>
