


















import Vue from 'vue';
//import VueRouter from 'vue-router'
//import HelloWorld from './components/HelloWorld.vue';
//import PlacerLogo from './components/PlacerLogo.vue';
import PlacerHeader from './components/PlacerHeader.vue';
import WelcomeScreen from './components/WelcomeScreen.vue';
import HowWeWork from './components/HowWeWork.vue';
import RecommendVisit from './components/RecommendVisit.vue';
import Partners from './components/Partners.vue';
import PlacerFooter from './components/PlacerFooter.vue';
//import HelloI18n from './components/HelloI18n.vue';
//import Test from './components/Test.vue';

export default Vue.extend({
    name: 'App',
    components: {
        PlacerHeader,
        WelcomeScreen,
        HowWeWork,
        RecommendVisit,
        Partners,
        PlacerFooter,
        //HelloWorld,
        //PlacerLogo,
        //HelloI18n,
        //Test,
    }
});
