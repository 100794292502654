<template>
  <section class="container how-we-work">
    <div class="row">

      <!-- Heading Title Start -->
      <div class="heading-title">
        <div class="heading-title__title">
          {{ $t('section.howWorkTitle') }}
        </div>
      </div>
      <!-- Heading Title End -->
      <p class="text" style="width:100%;">
          {{ $t('section.howWorkText') }}
      </p>

      <!-- Work Steps Start -->
      <div class="work-steps row">

        <!-- Work Steps Item Start -->
        <div class="work-steps__item col-lg-4 col-md-12 col-sm-12">
          <div class="work-steps__number">
            1
          </div>
          <div class="work-steps__item__content">
            <div class="work-steps__title">
              {{ $t('workSteps.step1Title') }}
            </div>
            <p class="work-steps__text">
              {{ $t('workSteps.step1Text') }}
            </p>
          </div>
        </div>
        <!-- Work Steps Item End -->

        <!-- Work Steps Item Start -->
        <div class="work-steps__item col-lg-4 col-md-12 col-sm-12">
          <div class="work-steps__number">
            2
          </div>
          <div class="work-steps__item__content">
            <div class="work-steps__title">
              {{ $t('workSteps.step2Title') }}
            </div>
            <p class="work-steps__text">
              {{ $t('workSteps.step2Text') }}
            </p>
          </div>

        </div>
        <!-- Work Steps Item End -->

        <!-- Work Steps Item Start -->
        <div class="work-steps__item col-lg-4 col-md-12 col-sm-12">
          <div class="work-steps__number">
            3
          </div>
          <div class="work-steps__item__content">
            <div class="work-steps__title">
              {{ $t('workSteps.step3Title') }}
            </div>
            <p class="work-steps__text">
              {{ $t('workSteps.step3Text') }}
            </p>
          </div>
        </div>
        <!-- Work Steps Item End -->

      </div>
      <!-- Work Steps End -->

    </div>
  </section>
</template>

<script>
export default {
  name: 'HowWeWork',
};
</script>


