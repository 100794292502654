<template>
    <div>
        ������
        <WelcomeScreen />
        <HowWeWork />
        <RecommendVisit :items=sportCards />
        <Partners />
        <FeedbackSection :feeds=feeds />
    </div>
</template>

<script>
    export default {
        name: 'IndexPage',

        data() {
            return {
                feeds: [],
                sportCards: [],
            }
        },

        mounted() {
            // this.fetch();
            //this.fetchFeedbacks();
            //this.fetchSportClubs();
        },

        methods: {
            // async fetch() {
            //   let data = await this.$axios.$get(this.$axios.defaults.baseURL + "/feedback/feedbacks");
            //   this.feeds = data;
            // },

            //async fetchFeedbacks() {
            //    let data = await this.$axios.$get(this.$axios.defaults.baseURL + "/feedback/feedbacks");
            //    this.feeds = data;
            //},

            //async fetchSportClubs() {
            //    let data = await this.$axios.$get(this.$axios.defaults.baseURL + "/sport-club/sportclubs");
            //    this.sportCards = data;
            //},

        },

    }
</script>
