<template>
    <section class="welcome-screen">

        <!-- Welcome Screen Image Background -->
        <!-- <img src="http://placer.good-landing.com.ua/img/_src/welcome__screen.jpg" class="welcome-screen__images" width="1920" height="951" alt=""> -->
        <img src="@/assets/img/_src/welcome__screen.jpg" class="welcome-screen__images" width="1920" height="951" alt="">

        <div class="container welcome-screen__content">
            <div class="welcome-screen__row row">
                <div class="welcome-screen__text">
                    {{ $t('welcomeScreen.text') }}
                </div>
                <div class="welcome-screen__h1">
                    {{ $t('welcomeScreen.h1') }}
                </div>

                <!-- Find Club Start -->
                <form-find-club />
                <!-- <FormFindClub /> -->
                <!--        <form class="find-club">-->
                <!--          <div class="find-club__item">-->
                <!--            <label class="find-club__label" for="find-club__date">-->
                <!--              <span>Когда</span>-->
                <!--            </label>-->
                <!--            <input id="find-club__date" class="find-club__date" type="date">-->
                <!--          </div>-->
                <!--          <div class="find-club__item">-->
                <!--            <label class="find-club__label" for="find-club__sport">-->
                <!--              <span>Вид спорта </span>-->
                <!--              <img src="http://placer.good-landing.com.ua/img/_src/find-club__arrow.svg" width="14" height="6" class="find-club__arrow" alt="">-->
                <!--            </label>-->
                <!--            <span id="find-club__sport" class="find-club__sport find-club__select">-->
                <!--							Все-->
                <!--						</span>-->
                <!--          </div>-->
                <!--          <div class="find-club__item">-->
                <!--            <label class="find-club__label" for="find-club_metro">-->
                <!--              <span>Метро </span>-->
                <!--              <img src="http://placer.good-landing.com.ua/img/_src/find-club__arrow.svg" width="14" height="6" class="find-club__arrow" alt="">-->
                <!--            </label>-->
                <!--            <span id="find-club_metro" class="find-club_metro find-club__select">-->
                <!--							- -->
                <!--						</span>-->
                <!--          </div>-->
                <!--          <div class="find-club__item">-->
                <!--            <label class="find-club__label" for="find-club_club">-->
                <!--              <span>Названия клуба </span>-->
                <!--              <img src="~/assets/img/_src/find-club__arrow.svg" width="14" height="6" class="find-club__arrow" alt="">-->
                <!--            </label>-->
                <!--            <span id="find-club_club" class="find-club_club find-club__select">-->
                <!--							- -->
                <!--						</span>-->
                <!--          </div>-->
                <!--          <div class="find-club__item">-->
                <!--            <input type="submit" class="find-club__submit" value="Найти">-->
                <!--          </div>-->
                <!--        </form>-->
                <!-- Find Club End -->
                <!--ifElse Spacer Start-->
                <div class="ifElse-spacer">
                    {{ $t('ifElse') }}
                </div>
                <!--ifElse Spacer End-->
                <!-- See All Clubs Start -->
                <NuxtLink to="/sport/club/search" class="see-all-clubs">
                    {{ $t('button.seeAll') }}
                </NuxtLink>
                <!-- See All Clubs End -->

            </div>
        </div>
    </section>
</template>

<script>
    // import FormFindClub from "~/components/form-find-club";
    export default {

        name: 'WelcomeScreen',

        // components: {FormFindClub},

        data() {
            return {
            }
        },

    };
</script>


